.form_item_currencies {
  width: 100% !important;

  // margin: 0% auto;
  :global .ant-form-item-label {
    label {
      margin-top: 0px !important;
    }
  }

  :global .ant-form-item-control-input {
    margin-top: 0px;
  }

  .select {
    width: 100% !important;
    padding: 0px !important;
    margin-top: -14px !important;
  }

}

.save_button_div {
  width: 100%;
  display: flex;
  justify-content: center;
}

.save_button {
  width: 150px;
}