.events {
    list-style: none;
    padding: 0;

    .event-list-item {
        display: flex;
        width: 100%;
        gap: 3px;

        margin-bottom: 3px;

        .reminder {
            width: 100%;
            padding-left: 3px;
            border-radius: 5px;
            background-color: #2e7379;
            color: #fff;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            &.completed {
                background-color: #f5aa41;
            }

            span {
                color: #fff !important;
            }
        }
    }
}