.table-rows {
    cursor: pointer;
}

.autocomplete-address-input {
    width: 100%;
    height: 32px;
    min-width: 0;
    padding: 4px 11px;
    color: rgba(0, 0, 0, .85);
    font-size: 14px;
    line-height: 1.5715;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    transition: all .3s;
}