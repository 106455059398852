.card {
    margin: 10px 0;


    label {
        margin-bottom: 5px;
    }
}

textarea {
    resize: none;
}

.invoice-statistics {

    .title {
        font-weight: 700;
        margin-bottom: 0;
    }

    .statistcs-cards {
        width: 100%;
        display: grid;
        gap: 1%;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));

        .card {
            min-width: 300px;
            min-height: 115px;
            width: 100%;
            transition: box-shadow 0.2s, border-color 0.2s;
            border-radius: 8px;

            .cards-header {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 8px;

                .icon-container {
                    padding: 6px;
                    width: 40px;
                    height: 40px;
                    border-radius: 4px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: rgb(255, 251, 230);
                }
            }

            .data {
                margin-top: 18px;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                align-items: center;
                gap: 1%;
                font-size: 16px;
            }

            &:hover {
                box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
            }
        }
    }
}