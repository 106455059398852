@import '../../../public/assets/style/variables.scss';

.devices_head_text {
    @include dashboard_font_text;
    font-size: 25px;

}

.close_outlined {
    color: $error_color;
}

.check_CircleFilled {
    color: $menu_iteam_color;
}

.stream-format-select {
    margin: 20px 0;
}

.deviceManager_row {
    cursor: pointer;
}