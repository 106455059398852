@import "../public/assets/style/variables.scss";
@import "../src/layout/style/colors.scss";

.profile-dropdown-component {
  min-width: 120px;

  .ant-dropdown-menu-item,
  .ant-dropdown-menu-submenu-title {
    padding: 0 !important;
  }

  .ant-dropdown-menu-item {
    .ant-dropdown-menu-item-active {
      margin: 0 !important;
    }
  }
}

.header-tigger-btn.ant-btn.ant-btn-text {

  &:hover,
  &:active {
    background-color: transparent;
  }
}

.ant-menu-item.sidbar_menu_iteam__UpxUY {
  position: relative;

  .pro-icon {
    position: absolute;
    top: 0px;
    right: 41px;
  }

  .refferal-resellerpro-icon {
    position: absolute;
    top: -5px !important;
    right: 2px !important;
  }
}

.ant-dropdown-menu {
  padding: 0 !important;
}

.ant-dropdown-menu-item-icon {
  margin-left: 8px;
}

.label-icon-container,
.submenu-label-icon-container {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
}

.phone-inp-container {
  display: flex;
  flex-direction: column;

  .phone-label {
    padding: 0 0 8px;
    line-height: 1.5715;
  }

  .phone-input {
    width: 100%;
    padding: 4px 11px;
    color: rgba(0, 0, 0, .85);
    font-size: 14px;
    line-height: 1.5715;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    transition: all .3s;
    height: 32px;
  }
}

.aplication-file {
  width: 100%;
  border-color: transparent !important;
  outline: none !important;
  box-shadow: none !important;

  &:focus {
    border-color: transparent;
    outline: none !important;
    box-shadow: none !important;
  }
}

.page-title {
  @include dashboard_font_text;
  font-size: 25px;
  margin-bottom: 20px !important;
}

@media (max-width: 576px) {
  .ant-table-filter-dropdown {
    position: relative;
  }

  .ant-picker-panel-container {
    position: absolute;
  }

  .ant-picker-panels {
    flex-direction: column !important;
  }
}

@media screen and (max-width: 800px) {
  .sidebar.ant-layout-sider.ant-layout-sider-dark {
    width: 100% !important;
    min-width: 100% !important;
    max-width: 100% !important;
  }

  .layout.ant-layout {
    min-height: 100vh;
  }
}


input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.center-btn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.float-right-btn {
  text-align: end !important;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date-today,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date-today {
  background: #e6f7ff !important;
}

textarea {
  resize: none !important;
}